@import "./_variables.scss";
@import "bootstrap/scss/bootstrap";
@import "./_bootswatch.scss";

@import "./fonts.scss";
@import "video.js/dist/video-js";
@import "videojs-hls-source-selector/dist/videojs-hls-source-selector";

@import "@fortawesome/fontawesome-free-webfonts/scss/fontawesome";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-regular";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-solid";

@import "./debconf18.scss";
